<template>
  <header>
    <div id="userHeader"
        v-if="$store.state.user.profile.id"
        :style="{ background: 'var(--userColor)' }"
        @click="$store.commit('user/EditProfile')">
      <div class="avatar-wrapper">
        <player-avatar :player="$store.state.user.profile" :scale="2.5"></player-avatar>
        <div class="pencil">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M421.7 220.3l-11.3 11.3-22.6 22.6-205 205c-6.6 6.6-14.8 11.5-23.8 14.1L30.8 511c-8.4 2.5-17.5 .2-23.7-6.1S-1.5 489.7 1 481.2L38.7 353.1c2.6-9 7.5-17.2 14.1-23.8l205-205 22.6-22.6 11.3-11.3 33.9 33.9 62.1 62.1 33.9 33.9zM96 353.9l-9.3 9.3c-.9 .9-1.6 2.1-2 3.4l-25.3 86 86-25.3c1.3-.4 2.5-1.1 3.4-2l9.3-9.3H112c-8.8 0-16-7.2-16-16V353.9zM453.3 19.3l39.4 39.4c25 25 25 65.5 0 90.5l-14.5 14.5-22.6 22.6-11.3 11.3-33.9-33.9-62.1-62.1L314.3 67.7l11.3-11.3 22.6-22.6 14.5-14.5c25-25 65.5-25 90.5 0z"/>
          </svg>
        </div>
      </div>
      <div class="username has-text-centered">
        <d-icon v-if="$store.getters['user/hasAdminAuthority']" pack="fas" icon="cog" size="is-small"
          style="top: 1px;" />
        <span class="has-text-weight-bold"> {{ $store.state.user.profile.username }} </span>
        <d-icon v-if="$store.state.user.profile.confirmed" pack="fas" icon="check" size="is-small"
          style="top: 3px" />
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: {},
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

header {
  height: 35px;
}
.avatar-wrapper {

  position: absolute;
  top: 5px;
  right: 28px;
  
}

.playerAvatar {
    box-shadow: 1px 1px 1px rgba($black, 0.16);
  }
  .pencil {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 1.2rem;
    width: 1.2rem;
    top: 33px;
    left: 38px;
    background: $white-bis;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
    box-shadow: 1px 1px 1px rgba($black, 0.29);
    color: $black-bis;

    svg {
      height: 11px;
    }
  }

.username {
  color: $white-bis;
  filter: drop-shadow(1px 1px 1px rgba($black,0.3))
}

.d-icon {
  position: relative;
  margin-left: .1em;
  margin-right: 2px
}

@media screen and (max-width: 350px)  {
  .username {
    font-size: .9em !important;
  }
}


</style>